import {dom} from 'core-utils';
import {SwipeGalleryElement} from './swipe-gallery-element';

export class SwipeGalleryWithThumbnailsElement extends SwipeGalleryElement {
	constructor() {
		super();
		this.gridItems = [];
	}

	/**
	 * static getter for defaults
	 * @static
	 * @returns {{classSliderAnimate: string, classActiveSlide: string, selectorGalleryThumbnailSlide: string, selectorGalleryThumbnailSlider: string, selectorGalleryThumbnailNavigationNext: string, selectorGalleryThumbnailNavigationPrev: string}} defaults
	 */
	static get defaults() {
		return {
			classSliderAnimate: 'sc-j-swipe-gallery-thumbnails-animate',
			classActiveSlide: 'active-slide',
			selectorGalleryThumbnailSlide: '.sc-j-swipe-gallery-thumbnails-slide',
			selectorGalleryThumbnailSlider: '.sc-j-swipe-gallery-thumbnails-slider',
			selectorGalleryThumbnailSliderWrapper: '.sc-j-swipe-gallery-thumbnails-slider-wrapper',
			selectorGalleryThumbnailNavigationNext: '.sc-j-swipe-gallery-thumbnail-navigation-next',
			selectorGalleryThumbnailNavigationPrev: '.sc-j-swipe-gallery-thumbnail-navigation-prev'
		};
	}

	/**
	 * connectedCallback
	 * @override
	 * @returns {void} void
	 */
	connectedCallback() {
		super.connectedCallback();
		this._thumbnailSlider.classList.add(SwipeGalleryWithThumbnailsElement.defaults.classSliderAnimate);
		this._highlightActiveThumb();
	}

	/**
	 * disconnectedCallback
	 * @override
	 * @returns {void} void
	 */
	disconnectedCallback() {
		super.disconnectedCallback();
		this.removeEvents();
	}

	/**
	 * initializeProperties
	 * @override
	 * @returns {void} void
	 */
	initializeProperties() {
		super.initializeProperties();
		this._thumbnails = dom.getElementsArray(SwipeGalleryWithThumbnailsElement.defaults.selectorGalleryThumbnailSlide, this);
		this._thumbnailSlider = dom.getElement(SwipeGalleryWithThumbnailsElement.defaults.selectorGalleryThumbnailSlider, this);
		this._thumbnailSliderNextButton = dom.getElement(SwipeGalleryWithThumbnailsElement.defaults.selectorGalleryThumbnailNavigationNext, this);
		this._thumbnailSliderPrevButton = dom.getElement(SwipeGalleryWithThumbnailsElement.defaults.selectorGalleryThumbnailNavigationPrev, this);
		this._thumbnailSliderWrapper = dom.getElement(SwipeGalleryWithThumbnailsElement.defaults.selectorGalleryThumbnailSliderWrapper, this);
	}

	/**
	 * bindContext
	 * @override
	 * @returns {void} void
	 */
	bindContext() {
		super.bindContext();
		this._thumbnailClickHandler = this._thumbnailClickHandler.bind(this);
		this._mouseOnThumbnailDownHandler = this._mouseOnThumbnailDownHandler.bind(this);
		this._mouseOnThumbnailsMoveHandler = this._mouseOnThumbnailsMoveHandler.bind(this);
		this._mouseUpThumbnailHandler = this._mouseUpThumbnailHandler.bind(this);
		this._thumbnailPrevButtonClickHandler = this._thumbnailPrevButtonClickHandler.bind(this);
		this._thumbnailNextButtonClickHandler = this._thumbnailNextButtonClickHandler.bind(this);
		this.resizeHandler = this.resizeHandler.bind(this);
		if (this._isScrollSwipeEnabled) {
			this._thumbnailScrollHandler = this._thumbnailScrollHandler.bind(this);
		}
	}

	/**
	 * addEvents
	 * @returns {void} void
	 */
	addEvents() {
		super.addEvents();
		this.addMultipleEvent(this._thumbnailSliderWrapper, this._mouseOnThumbnailDownHandler, SwipeGalleryElement.swipeEvents.DOWN);
		window.addEventListener('resize', this.resizeHandler);
		this._thumbnailSliderNextButton.addEventListener('click', this._thumbnailNextButtonClickHandler);
		this._thumbnailSliderPrevButton.addEventListener('click', this._thumbnailPrevButtonClickHandler);
		if (this._isScrollSwipeEnabled) {
			this.addMultipleEvent(this._thumbnailSliderWrapper, this._thumbnailScrollHandler, SwipeGalleryElement.mouseWheelEvents.MOVE);
		}
	}

	/**
	 * getter for thumbnails translate 3d x value
	 * @returns {number} translate 3d x value
	 * @private
	 */
	get _thumbnailTranslate3d() {
		return parseFloat(SwipeGalleryElement.getTranslate3d(this._thumbnailSlider)[0].replace('px', ''));
	}

	/**
	 * removeEvents
	 * @returns {void} void
	 */
	removeEvents() {
		super.removeEvents();
		this.gridItems.forEach(item => {
			item.removeEventListener('click', this._gridItemClickHandler);
		});
		this.removeMultipleEvent(this._thumbnailSliderWrapper, this._mouseOnThumbnailDownHandler, SwipeGalleryElement.swipeEvents.DOWN);
		window.removeEventListener('resize', this.resizeHandler);
		if (this._isScrollSwipeEnabled) {
			this.removeMultipleEvent(this._thumbnailSliderWrapper, this._thumbnailScrollHandler, SwipeGalleryElement.mouseWheelEvents.MOVE);
		}
	}

	/**
	 * resizeHandler
	 * @override
	 * @returns {void} void
	 */
	resizeHandler() {
		super.resizeHandler();
		const activeThumb = this._activeThumb || this._thumbnails[0];
		this._centerThumbnail(activeThumb, true);
	}

	/**
	 * setNewIndex
	 * @param {number} index index
	 * @returns {void} void
	 */
	setNewIndex(index) {
		super.setNewIndex(index);
		this._highlightActiveThumb();
	}

	/**
	 * _thumbnailNextButtonClickHandler
	 * @private
	 * @returns {void} void
	 */
	_thumbnailNextButtonClickHandler() {
		const intersectedThumbnails = this._getVisibleThumbnails();
		const targetElement = intersectedThumbnails[intersectedThumbnails.length - 1];
		this._centerThumbnail(targetElement);
	}

	/**
	 * _thumbnailPrevButtonClickHandler
	 * @private
	 * @returns {void} void
	 */
	_thumbnailPrevButtonClickHandler() {
		const intersectedThumbnails = this._getVisibleThumbnails();
		const targetElement = intersectedThumbnails[0];
		this._centerThumbnail(targetElement);
	}

	/**
	 * _getVisibleThumbnails
	 * @returns {array} array with visible thumbnails (intersected as well)
	 * @private
	 */
	_getVisibleThumbnails() {
		return this._thumbnails.filter(thumbnail => dom.isVisible(thumbnail, this, false));
	}

	/**
	 * _thumbnailScrollHandler
	 * @param {Event} event event
	 * @private
	 * @returns {void} void
	 */
	_thumbnailScrollHandler(event) {
		if (Math.abs(event.deltaY) < Math.abs(event.deltaX)) {
			event.preventDefault();
			this._thumbnailSlider.classList.remove(SwipeGalleryWithThumbnailsElement.defaults.classSliderAnimate);
			this._transformThumbnailSlider(this._thumbnailTranslate3d - event.deltaX);
			this._showHideThumbnailArrows();
		}
	}

	/**
	 * _mouseOnThumbnailDownHandler
	 * @param {Event} event event
	 * @private
	 * @returns {void} void
	 */
	_mouseOnThumbnailDownHandler(event) {
		this._slideWidth = this.clientWidth;
		this._thumbnailSlider.classList.remove(SwipeGalleryWithThumbnailsElement.defaults.classSliderAnimate);

		this._touchStartX = SwipeGalleryElement.getEventXPosition(event);
		this._initialValue = parseInt(SwipeGalleryElement.getTranslate3d(this._thumbnailSlider)[0].replace('px', ''), 10);

		const clickedThumbnail = event.target.classList.contains(SwipeGalleryWithThumbnailsElement.defaults.selectorGalleryThumbnailSlide) ? event.target : dom.closest(event.target, SwipeGalleryWithThumbnailsElement.defaults.selectorGalleryThumbnailSlide);
		if (clickedThumbnail) {
			this._clickedIndex = parseInt(clickedThumbnail.getAttribute('data-index'), 10);
		}

		this.addMultipleEvent(this._thumbnailSliderWrapper, this._mouseOnThumbnailsMoveHandler, SwipeGalleryElement.swipeEvents.MOVE);
		this.addMultipleEvent(this._thumbnailSliderWrapper, this._mouseUpThumbnailHandler, SwipeGalleryElement.swipeEvents.UP);
		this.addMultipleEvent(this._thumbnailSliderWrapper, this._mouseUpThumbnailHandler, SwipeGalleryElement.swipeEvents.OUT);
	}

	/**
	 * _mouseOnThumbnailsMoveHandler
	 * @param {Event} event event
	 * @private
	 * @returns {void} void
	 */
	_mouseOnThumbnailsMoveHandler(event) {
		this._touchMoveX = SwipeGalleryElement.getEventXPosition(event);
		this._transformThumbnailSlider(this._initialValue - (this._touchStartX - this._touchMoveX));
	}

	/**
	 * _thumbnailClickHandler
	 * @param {number} index index
	 * @private
	 * @returns {void} void
	 */
	_thumbnailClickHandler(index) {
		this.setNewIndex(index);
		this._thumbnailSlider.classList.add(SwipeGalleryWithThumbnailsElement.defaults.classSliderAnimate);
		this._transformSlider(this._calculatedIndex * this.clientWidth);
		this._thumbnailSlider.classList.remove(SwipeGalleryWithThumbnailsElement.defaults.classSliderAnimate);
	}

	/**
	 * _transformThumbnailSlider
	 * @param {number} targetValue target x value
	 * @private
	 * @returns {void} void
	 */
	_transformThumbnailSlider(targetValue) {
		const needsZeroOffset = this.needZeroOffset();
		const maxValue = needsZeroOffset ? this._maxScrollLength : 0;
		const minValue = needsZeroOffset ? this._maxScrollLength : (this._thumbnailSliderWidth - this._thumbnailSliderWrapper.clientWidth);

		let targetXPosition = targetValue > maxValue ? maxValue : targetValue;
		targetXPosition = targetXPosition < -minValue ? -minValue : targetXPosition;
		this._thumbnailSlider.style.transform = `translate3d(${targetXPosition}px,0,0)`;
	}

	/**
	 * _mouseUpThumbnailHandler
	 * @param {event} event event
	 * @private
	 * @returns {void} void
	 */
	_mouseUpThumbnailHandler(event) {
		const deltaX = Math.abs(this._touchStartX - SwipeGalleryElement.getEventXPosition(event));
		if (deltaX < 3) {
			this._thumbnailClickHandler(this._clickedIndex);
		}
		this._showHideThumbnailArrows();
		this.removeMultipleEvent(this._thumbnailSliderWrapper, this._mouseOnThumbnailsMoveHandler, SwipeGalleryElement.swipeEvents.MOVE);
		this.removeMultipleEvent(this._thumbnailSliderWrapper, this._mouseUpThumbnailHandler, SwipeGalleryElement.swipeEvents.UP);
		this.removeMultipleEvent(this._thumbnailSliderWrapper, this._mouseUpThumbnailHandler, SwipeGalleryElement.swipeEvents.OUT);
	}

	/**
	 * _highlightActiveThumb
	 * @private
	 * @returns {void} void
	 */
	_highlightActiveThumb() {
		this._thumbnails.forEach((thumbnail, index) => {
			thumbnail.classList.remove(SwipeGalleryWithThumbnailsElement.defaults.classActiveSlide);
			if (index === this._index) {
				this._activeThumb = thumbnail;
				thumbnail.classList.add(SwipeGalleryWithThumbnailsElement.defaults.classActiveSlide);
			}
		});
		this._centerThumbnail(this._activeThumb);
		this._showHideThumbsWithTimeout();
	}

	/**
	 * _showHideThumbsWithTimeout
	 * @private
	 * @returns {void} void
	 */
	_showHideThumbsWithTimeout() {
		setTimeout(() => {
			this._showHideThumbnailArrows();
		}, 300);
	}

	/**
	 * _showHideThumbnailArrows
	 * @private
	 * @returns {void} void
	 */
	_showHideThumbnailArrows() {
		if (this._thumbnails.length > 0) {
			if (dom.isVisible(this._thumbnails[0], this._thumbnailSliderWrapper, true)) {
				this._thumbnailSliderPrevButton.classList.add(SwipeGalleryElement.defaults.hiddenClass);
			}
			else {
				this._thumbnailSliderPrevButton.classList.remove(SwipeGalleryElement.defaults.hiddenClass);
			}
			if (dom.isVisible(this._thumbnails[this._thumbnails.length - 1], this, true)) {
				this._thumbnailSliderNextButton.classList.add(SwipeGalleryElement.defaults.hiddenClass);
			}
			else {
				this._thumbnailSliderNextButton.classList.remove(SwipeGalleryElement.defaults.hiddenClass);
			}
		}
	}

	/**
	 * getter for thumbnail width - _thumbnailClientWidth
	 * @returns {number} - thumbnail css computed width
	 * @private
	 */
	get _thumbnailClientWidth() {
		return this._thumbnails.length ? SwipeGalleryElement.getComputedPropertyValue(this._thumbnails[0], 'width') : 0;
	}

	/**
	 * _maxScrollLength
	 * @returns {number} zero offset
	 * @private
	 */
	get _maxScrollLength() {
		if (this._thumbnailSliderWidth > this._thumbnailSlider.clientWidth) {
			return (this._thumbnailSliderWidth - this._thumbnailSlider.clientWidth) / 2;
		}
		return 0;
	}

	/**
	 * _thumbnailSliderWidth
	 * @returns {number} calculated thumb slider width includes margins
	 * @private
	 */
	get _thumbnailSliderWidth() {
		return this._thumbnails.length * (this._thumbnailClientWidth + SwipeGalleryElement.getComputedPropertyValue(this._thumbnails[0], 'margin-right') + SwipeGalleryElement.getComputedPropertyValue(this._thumbnails[0], 'margin-left'));
	}

	/**
	 * _zeroOffset - getter for zero offset
	 * @returns {number} zero offset
	 * @private
	 */
	get _zeroOffset() {
		const needsZeroOffset = this.needZeroOffset();
		return needsZeroOffset ? (this._thumbnailSliderWidth - this._thumbnailSlider.clientWidth) / 2 : 0;
	}

	needZeroOffset() {
		const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
		const hasJustifyContentCenter = getComputedStyle(this._thumbnailSlider, null).getPropertyValue('justify-content') === 'center';
		if (isIE11) {
			return false;
		}
		else {
			return hasJustifyContentCenter;
		}
	}

	/**
	 * _centerThumbnail
	 * @param {HTMLElement} thumbnail thumbnail
	 * @param {boolean} all if all or only intersected
	 * @private
	 * @returns {void} void
	 */
	_centerThumbnail(thumbnail, all) {
		if (thumbnail && (all || !dom.isVisible(thumbnail, this, true))) {
			const index = thumbnail.getAttribute('data-index');
			this._thumbnailSlider.classList.remove(SwipeGalleryWithThumbnailsElement.defaults.classSliderAnimate);
			const targetXPosition = this._zeroOffset - (index * this._thumbnailClientWidth) + (this._thumbnailSlider.clientWidth / 2) - (this._thumbnailClientWidth / 2);
			this._transformThumbnailSlider(targetXPosition);
			this._thumbnailSlider.classList.add(SwipeGalleryWithThumbnailsElement.defaults.classSliderAnimate);
		}
		this._showHideThumbsWithTimeout();
	}
}
if (window.customElements.get('swipe-gallery-with-thumbnails-element') === undefined) {
	window.customElements.define('swipe-gallery-with-thumbnails-element', SwipeGalleryWithThumbnailsElement);
}


