import {SwipeGalleryElement} from './swipe-gallery-element';
import {dom} from 'core-utils';

export class CompareSwipeGalleryElement extends SwipeGalleryElement {
	constructor() {
		super();
	}

	/**
	 * static getter for defaults - selectors, strings, attributes
	 * @static
	 * @returns {object} defaults
	 */
	static get defaults() {
		return {
			'classCompare': '.sc-module-compare',
			'classSwiping': 'sc-compare-swipe'
		};
	}

	/**
	 * connectedCallback
	 * @returns {void} void
	 */
	connectedCallback() {
		super.connectedCallback();
		this._initDomElements();
		this._setSwipingClass();
		this._setVisibleSlide();
	}

	/**
	 * _initDomElements
	 * @returns {void} void
	 * @private
	 */
	_initDomElements() {
		this.compareModule = dom.getElement(CompareSwipeGalleryElement.defaults.classCompare);
	}

	/**
	 * _setSwipingClass
	 * @private
	 * @returns {void} void
	 */
	_setSwipingClass() {
		this.compareModule.classList.add(CompareSwipeGalleryElement.defaults.classSwiping);
	}

	/**
	 * _setVisibleSlide
	 * @param {number} index index
	 * @private
	 * @returns {void} void
	 */
	_setVisibleSlide(index = 0) {
		this.compareModule.setAttribute('data-visible-slide', index);
	}

	/**
	 * setNewIndex
	 * @param {number} index index
	 * @returns {void} void
	 */
	setNewIndex(index) {
		super.setNewIndex(index);
		this._setVisibleSlide(this._index);
	}
}

if (window.customElements.get('compare-swipe-gallery') === undefined) {
	window.customElements.define('compare-swipe-gallery', CompareSwipeGalleryElement);
}
