import {template} from 'core-utils';
import {DynamicSwipeGalleryLookup} from './dynamic-templates/dynamic-swipe-gallery-lookup';
import {SwipeGalleryElement} from './swipe-gallery-element';

export class DynamicSwipeGalleryElement extends SwipeGalleryElement {
	constructor() {
		super();
		this.needsUpdateContent = this.needsUpdateContent.bind(this);
	}

	/**
	 * defaults - getter for defaults
	 * @returns {{classHidden: string}} defaults object
	 */
	static get defaults() {
		return {
			classHidden: 'sc-hidden'
		};
	}

	/**
	 * connectedCallback - overwrites SwipeGalleryElement's connectedCallback function
	 * @returns {void} void
	 */
	connectedCallback() {
		this.BREAKPOINT = Number(this.dataset.breakpoint) || 480;
		this.NUMBER_OF_ITEMS_MOBILE = Number(this.dataset.numberOfItemsMobile) || 1;
		this.NUMBER_OF_ITEMS_DESKTOP = Number(this.dataset.numberOfItemsDesktop) || 3;
		this.savedViewPort = DynamicSwipeGalleryElement.viewPortWidth;
		this._updateDynamicContent();
		super.connectedCallback();
		super.addEvents();
		super._initGalleryInstance();
	}

	/**
	 * viewPortWidth
	 * @returns {number} viewPortWidth
	 */
	static get viewPortWidth() {
		return document.documentElement.clientWidth;
	}

	/**
	 * _updateDynamicContent
	 * @private
	 * @returns {void} returns void
	 */
	_updateDynamicContent() {
		const {template: templateName = ''} = this.dataset;
		const templateFile = DynamicSwipeGalleryLookup.getTemplate(templateName);

		if (!!templateFile) {
			const {content: contentString = {}} = this.dataset;
			this._renderDynamicContent(DynamicSwipeGalleryElement._getParsedContent(contentString), templateFile);
		}
	}

	/**
	 * _renderDynamicContent
	 * @param {array} content content
	 * @param {string} templateFile templateFile string
	 * @private
	 * @returns {void} void
	 */
	_renderDynamicContent(content, templateFile) {
		if (DynamicSwipeGalleryElement.viewPortWidth >= this.BREAKPOINT) {
			const preparedContent = this.prepareDataForTemplate(content, this.NUMBER_OF_ITEMS_DESKTOP);
			this._renderPreparedContent(templateFile, preparedContent, this.NUMBER_OF_ITEMS_DESKTOP);
		}
		else {
			const preparedContent = this.prepareDataForTemplate(content, this.NUMBER_OF_ITEMS_MOBILE);
			this._renderPreparedContent(templateFile, preparedContent, this.NUMBER_OF_ITEMS_MOBILE);
		}
	}

	/**
	 * _getParsedContent
	 * @param {string} contentString contentString
	 * @returns {Array} content parsed content
	 * @private
	 */
	static _getParsedContent(contentString) {
		let content = [];
		try {
			content = JSON.parse(contentString);
		}
		catch (error) {
			console.log('Cannot parse content');
		}
		return content;
	}

	/**
	 * _renderPreparedContent
	 * @param {String} templateFile template file string
	 * @param {Array} preparedContent preparedContent
	 * @param {Number} numberOfItems numberOfItems
	 * @private
	 * @returns {void} void
	 */
	_renderPreparedContent(templateFile = '', preparedContent = [], numberOfItems = 1) {
		const templateString = template.render(templateFile, {
			preparedContent,
			numberOfItems: numberOfItems
		});
		this._updateContent(templateString);
	}

	/**
	 * prepareDataForTemplate
	 * @param {Array} content content
	 * @param {Number} numberOfItems numberOfItems
	 * @returns {Array} slides slides
	 */
	prepareDataForTemplate(content = [], numberOfItems) {
		const slidesContent = [...content];
		const numberOfSlidesContainer = slidesContent.length / numberOfItems;

		const slides = [];
		for (let index = 0; index < numberOfSlidesContainer; index++) {
			slides.push(DynamicSwipeGalleryElement.createSlideItems(numberOfItems, slidesContent));
		}

		return slides;
	}

	/**
	 * createSlideItems
	 * @param {Number} numberOfItems numberOfItems
	 * @param {Array} slidesContent slidesContent
	 * @returns {Array} slideItems slideItems
	 */
	static createSlideItems(numberOfItems, slidesContent) {
		const slideItems = [];
		const content = slidesContent;
		let j = numberOfItems;

		while (j--) {
			if (!content.length) {
				break;
			}
			slideItems.push(content.shift());
		}

		return slideItems;
	}

	/**
	 * _updateContent
	 * @param {String} templateString templateString
	 * @private
	 * @returns {void} void
	 */
	_updateContent(templateString) {
		this.innerHTML = templateString;
		this.classList.remove(DynamicSwipeGalleryElement.defaults.classHidden);
	}

	/**
	 * _getNewIndex
	 * @param {Number} index index
	 * @returns {Number} new index
	 * @private
	 */
	_getNewIndex(index = 0) {
		if (DynamicSwipeGalleryElement.viewPortWidth >= this.BREAKPOINT) {
			return parseInt(index / this.NUMBER_OF_ITEMS_DESKTOP, 10);
		}
		else {
			return index * this.NUMBER_OF_ITEMS_DESKTOP;
		}
	}

	/**
	 * resizeHandler - overwrites SwipeGallery's resizeHandler function
	 * @returns {void} void
	 */
	resizeHandler() {
		if (this.needsUpdateContent()) {
			this.disconnectedCallback();
			this.connectedCallback();
			this.dataset.index = this._getNewIndex(this._calculatedIndex);
		}
	}

	/**
	 * needsUpdateContent
	 * @returns {boolean} update or not
	 */
	needsUpdateContent() {
		const currentAndLastViewPortWidthSmallerThanBreakpoint = this.savedViewPort < this.BREAKPOINT && DynamicSwipeGalleryElement.viewPortWidth < this.BREAKPOINT;
		const currentAndLastViewPortWidthGreaterThanBreakpoint = this.savedViewPort > this.BREAKPOINT && DynamicSwipeGalleryElement.viewPortWidth > this.BREAKPOINT;
		return !(currentAndLastViewPortWidthSmallerThanBreakpoint || currentAndLastViewPortWidthGreaterThanBreakpoint);
	}
}

if (window.customElements.get('dynamic-swipe-gallery-element') === undefined) {
	window.customElements.define('dynamic-swipe-gallery-element', DynamicSwipeGalleryElement);
}
