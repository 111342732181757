import {STCK_STORE, SELECTORS} from '@oneaudi/stck-store';
import {SwipeGalleryElement} from '../swipe-gallery-element';
import {lazyLoad} from '../../stockcars-utils-bundle';
import {scPage} from '../../stockcars-utils-bundle';


export class AozSwipeGalleryElement extends SwipeGalleryElement {
	constructor() {
		super();
		this._mouseLeaveHandler = this._mouseLeaveHandler.bind(this);
	}

	static get defaults() {
		return {
			elementName: 'aoz-swipe-gallery-element',
			hiddenClass: 'sc-hidden',
			transparentClass: 'sc-transparent',
			classPseudoActiveDot: 'sc-j-swipe-gallery-pseudo-active-dot',
			DESKTOP_VIEWPORT: 768
		};
	}

	/**
	 * connectedCallback
	 * @returns {void} void
	 */
	connectedCallback() {
		super.connectedCallback();
		this._initClassVariables();
		this._renderStateCount();
		this.unsubscribeAozState = STCK_STORE.observeStateFromStore(SELECTORS.AOZ.getAozStateRaw, this._renderStateCount.bind(this));
		this._setActiveDot();
		lazyLoad.register(this);
	}

	_initClassVariables() {
		const {productIds = ''} = this.dataset;
		this._productIds = productIds.split(',');
		this._lastViewPortSize = window.innerWidth;
		this._groupHeadline = this.querySelector('.sc-aoz-swipe-gallery-title');
		this._groupHeadlineCount = this.querySelector('.sc-aoz-swipe-gallery-title .sc-j-aoz-gallery-added-items-count');
		this._groupBannerCounts = [...this.querySelectorAll('.sc-aoz-gallery-slide-category-text .sc-j-aoz-gallery-added-items-count')];
		this._vehicleId = scPage.getVehicleIdFromUrl();
	}

	disconnectedCallback() {
		super.disconnectedCallback();
		this.unsubscribeAozState();
	}

	_dotClickHandler(event) {
		event.preventDefault();
		const index = Number(event.target.getAttribute('data-index'));
		if (index > -1) {
			this.setNewIndex(index);
			this._slider.classList.add(SwipeGalleryElement.defaults.classSliderAnimate);
			this._transformSlider((index + this.additionalClonesCount) * this.clientWidth);
		}
	}

	_isCorrectSwipeDistance(absoluteSwipeDistance) {
		return absoluteSwipeDistance > 2;
	}

	_mouseDownHandler(event) {
		super._mouseDownHandler(event);
		this._slider.addEventListener('mouseleave', this._mouseLeaveHandler);
	}

	_mouseUpHandler(event) {
		this._slider.removeEventListener('mouseleave', this._mouseLeaveHandler);
		super._mouseUpHandler(event);
	}

	_mouseLeaveHandler(event) {
		this._mouseUpHandler(event);
		this._slider.removeEventListener('mouseleave', this._mouseLeaveHandler);
	}

	_renderStateCount() {
		const vehicleAozState = SELECTORS.AOZ.getAddedProductsByVehicleId(STCK_STORE.state, this._vehicleId);
		let groupCount = this._getGroupCount(vehicleAozState);
		if (vehicleAozState && groupCount) {
			this._renderGroupCount(groupCount);
		}
		else {
			this._resetGroupCount();
		}
	}

	_resetGroupCount() {
		this._groupHeadlineCount.innerHTML = '';
		this._groupHeadlineCount.parentNode.classList.add(AozSwipeGalleryElement.defaults.transparentClass);
		this._groupBannerCounts.forEach(count => {
			count.innerHTML = '';
			count.parentNode.classList.add(AozSwipeGalleryElement.defaults.hiddenClass);
		});
	}

	_renderGroupCount(groupCount) {
		this._groupHeadlineCount.innerHTML = groupCount;
		this._groupHeadlineCount.parentNode.classList.remove(AozSwipeGalleryElement.defaults.transparentClass);
		this._groupBannerCounts.forEach(count => {
			count.innerHTML = groupCount;
			count.parentNode.classList.remove(AozSwipeGalleryElement.defaults.hiddenClass);
		});
	}

	_getGroupCount(vehicleAozState = []) {
		return this._productIds.reduce((acc, currentProductId) => {
			if (vehicleAozState.find(product => product.ean === currentProductId)) {
				return acc + 1;
			}
			return acc;
		}, 0);
	}

	_prevButtonClickHandler() {
		if (this._isInfiniteEnabled && this._index === (this.additionalClonesCount * -1)) {
			return;
		}
		this._slider.classList.add(SwipeGalleryElement.defaults.classSliderAnimate);
		this.setNewIndex(this._index - 1);
		this._transformSlider(this._calculatedIndex * this.clientWidth);
	}

	setNewIndex(index) {
		if (isNaN(index)) {
			const slideCount = (this._absoluteSwipeDistance / this._slideWidth) >= 1 ? (this._absoluteSwipeDistance / this._slideWidth) : 1;
			const incrementValue = Math.round(slideCount);
			if (this._moveX > this._calculatedIndex * this.clientWidth && this._calculatedIndex < this._slidesCount + this.additionalClonesCount) {
				this._index = (this._index + incrementValue) <= (this._slidesCount + 1) ? (this._index + incrementValue) : this._slidesCount + 1;
			}
			else if (this._moveX < this._calculatedIndex * this.clientWidth && this._calculatedIndex > 0) {
				this._index = (this._index - incrementValue) >= -(this.additionalClonesCount) ? this._index - incrementValue : -(this.additionalClonesCount);
			}
		}
		else {
			this._index = index;
		}
		this._setActiveCount();
		this._setActiveDot();
		this._lazyLoadImages();
		this._showPrevNextButtons();
	}

	_handleAnimationDone() {
		let raiser = 0;
		if (this._index === this.additionalClonesCount * -1 || this._index === this.additionalClonesCount * -2) {
			if (this._index === this.additionalClonesCount * -2) {
				raiser = 1;
			}
			this._slider.classList.remove(SwipeGalleryElement.defaults.classSliderAnimate);
			this.setNewIndex(this._slidesCount - (this.additionalClonesCount - raiser));
			this._slider.style.transform = `translate3d(-${this._slideWidth * this._slidesCount - (this.additionalClonesCount - raiser)}px,0,0)`;
		}
		else if (this._index === this._slidesCount || this._index === this._slidesCount + 1) {
			if (this._index === this._slidesCount + 1) {
				raiser = 1;
			}
			this._slider.classList.remove(SwipeGalleryElement.defaults.classSliderAnimate);
			this.setNewIndex(raiser);
			this._slider.style.transform = `translate3d(-${this._slideWidth * (this.additionalClonesCount + raiser)}px,0,0)`;
		}
		this._isAnimationActive = false;
	}

	_setActiveDot() {
		let checkedIndices = this.getIndicesToBeChecked();
		let actuallyIndex = this._getActuallyIndex();

		this._dots.forEach((dot, index) => {

			dot.classList.remove(AozSwipeGalleryElement.defaults.classPseudoActiveDot);
			dot.classList.remove(SwipeGalleryElement.defaults.classActiveDot);
			if (checkedIndices.includes(index)) {
				dot.classList.add(SwipeGalleryElement.defaults.classActiveDot);
				if (actuallyIndex !== index) {
					dot.classList.add(AozSwipeGalleryElement.defaults.classPseudoActiveDot);
				}
			}
		});

		this._toggleCountsVisibility(actuallyIndex);
	}

	_toggleCountsVisibility(pseudoIndex) {
		if (this._groupHeadlineCount && this._groupBannerCounts) {
			let indices = [this._slidesCount, this._slidesCount - 2, this._slidesCount - 1, 0];
			if (window.innerWidth < AozSwipeGalleryElement.defaults.DESKTOP_VIEWPORT) {
				indices = [0];
			}

			if (indices.includes(pseudoIndex)) {
				this._groupHeadline.classList.add(AozSwipeGalleryElement.defaults.transparentClass);
				this._groupBannerCounts.forEach(bannerCount => {
					bannerCount.parentNode.classList.remove(AozSwipeGalleryElement.defaults.transparentClass);
				});
			}
			else {
				this._groupHeadline.classList.remove(AozSwipeGalleryElement.defaults.transparentClass);
				this._groupBannerCounts.forEach(bannerCount => {
					bannerCount.parentNode.classList.add(AozSwipeGalleryElement.defaults.transparentClass);
				});
			}
		}
	}

	_getActuallyIndex() {
		let actuallyIndex = this._index;

		if (this._index === -1) {
			actuallyIndex = this._slidesCount - 1;
		}
		else if (this._index === -2) {
			actuallyIndex = this._slidesCount - 2;
		}
		else if (this._index === -3) {
			actuallyIndex = this._slidesCount - 3;
		}
		return actuallyIndex;
	}

	getIndicesToBeChecked() {
		if (this._index === -1) {
			return [this._slidesCount - 1, this._index + 1, this._index + 2];
		}
		else if (this._index === -2) {
			return [this._slidesCount - 2, this._slidesCount - 1, this._index + 2];
		}
		else if (this._index === -3) {
			return [this._slidesCount - 3, this._slidesCount - 1, this._slidesCount - 2];
		}
		else if (this._index === this._slidesCount - 2) {
			return [this._index, this._slidesCount - 1, 0];
		}
		else if (this._index === this._slidesCount - 1) {
			return [this._index, 0, 1];
		}
		else {
			return [this._index, this._index + 1, this._index + 2];
		}
	}

	get _calculatedIndex() {
		return this._index + this.additionalClonesCount;
	}

	// eslint-disable-next-line max-statements
	_getClonedSlides() {
		const firstSlideCLone = this._slides[0].cloneNode(true);
		const lastSlideClone = this._slides[this._slides.length - 1].cloneNode(true);
		let secondSlideClone, penultimateSlideClone, thirdSlideClone, prePenultimateSlideClone;

		firstSlideCLone.setAttribute('data-original-index', 0);
		lastSlideClone.setAttribute('data-original-index', this._slides.length - 1);
		firstSlideCLone.classList.add('sc-j-aoz-clone');
		lastSlideClone.classList.add('sc-j-aoz-clone');

		if (this.additionalClonesCount > 1) {
			secondSlideClone = this._slides[1].cloneNode(true);
			secondSlideClone.setAttribute('data-original-index', 1);
			secondSlideClone.classList.add('sc-j-aoz-clone');

			penultimateSlideClone = this._slides[this._slides.length - 2].cloneNode(true);
			penultimateSlideClone.setAttribute('data-original-index', this._slides.length - 2);
			penultimateSlideClone.classList.add('sc-j-aoz-clone');


			if (this.additionalClonesCount > 2) {
				thirdSlideClone = this._slides[2].cloneNode(true);
				thirdSlideClone.setAttribute('data-original-index', 2);
				thirdSlideClone.classList.add('sc-j-aoz-clone');

				prePenultimateSlideClone = this._slides[this._slides.length - 3].cloneNode(true);
				prePenultimateSlideClone.setAttribute('data-original-index', this._slides.length - 3);
				prePenultimateSlideClone.classList.add('sc-j-aoz-clone');
			}
		}

		return {
			firstSlideCLone,
			lastSlideClone,
			secondSlideClone,
			thirdSlideClone,
			penultimateSlideClone,
			prePenultimateSlideClone
		};
	}

	resizeHandler() {
		if (this._lastViewPortSize < AozSwipeGalleryElement.defaults.DESKTOP_VIEWPORT) {
			if (window.innerWidth > AozSwipeGalleryElement.defaults.DESKTOP_VIEWPORT) {
				if (this._slidesCount <= 3) {
					this._handleAozSwipeGalleryResizeForLowerThreeItems();
				}
				else {
					this._handleAozSwipeGalleryResize();
				}
			}
			else {
				this._generalResize();
			}
		}
		else {
			if (window.innerWidth < AozSwipeGalleryElement.defaults.DESKTOP_VIEWPORT) {
				this._handleAozSwipeGalleryResize();
			}
			else {
				this._generalResize();
			}
		}
		this._lastViewPortSize = window.innerWidth;
	}

	_generalResize() {
		if (this._slidesCount > 3) {
			super.resizeHandler();
		}
		else {
			this._transformSlider(0);
		}
	}

	_handleAozSwipeGalleryResizeForLowerThreeItems() {
		this._removeClones();
		this._reInitAozSwipeGalleryElement();
		this.setNewIndex(0);
		this._slider.classList.remove(SwipeGalleryElement.defaults.classSliderAnimate);
		this._transformSlider(0);
		this._slider.classList.add(SwipeGalleryElement.defaults.classSliderAnimate);
	}

	_handleAozSwipeGalleryResize() {
		const actuallyIndex = this._getActuallyIndex();
		this._removeClones();
		this._reInitAozSwipeGalleryElement();
		this.setNewIndex(actuallyIndex);
		this._slider.classList.remove(SwipeGalleryElement.defaults.classSliderAnimate);
		this._transformSlider((actuallyIndex + this.additionalClonesCount) * this.clientWidth);
		this._slider.classList.add(SwipeGalleryElement.defaults.classSliderAnimate);
	}

	_removeClones() {
		[...this.querySelectorAll('.sc-j-aoz-clone')].forEach(clone => {
			clone.parentElement.removeChild(clone);
		});
	}

	_reInitAozSwipeGalleryElement() {
		this.disconnectedCallback();
		this.connectedCallback();
	}

	addEvents() {
		const dotsContainer = this.querySelector('.sc-aoz-gallery-dots.sc-j-swipe-gallery-dots');
		if (window.innerWidth >= AozSwipeGalleryElement.defaults.DESKTOP_VIEWPORT && this._slidesCount <= 3) {
			this.classList.add(SwipeGalleryElement.defaults.classHideCursorMove);
			dotsContainer.classList.add(SwipeGalleryElement.defaults.hiddenClass);
			this._prevButton.classList.add(SwipeGalleryElement.defaults.hiddenClass);
			this._nextButton.classList.add(SwipeGalleryElement.defaults.hiddenClass);
			window.addEventListener('resize', this._debounceResizeHandler);
			return;
		}
		this.classList.remove(SwipeGalleryElement.defaults.classHideCursorMove);
		dotsContainer.classList.remove(SwipeGalleryElement.defaults.hiddenClass); // TODO
		super.addEvents();
	}

	_toggleNextButton() {
		if (window.innerWidth >= AozSwipeGalleryElement.defaults.DESKTOP_VIEWPORT && this._slidesCount <= 3) {
			return;
		}
		super._toggleNextButton();
	}

	_togglePrevButton() {
		if (window.innerWidth >= AozSwipeGalleryElement.defaults.DESKTOP_VIEWPORT && this._slidesCount <= 3) {
			return;
		}
		super._togglePrevButton();
	}

	_insertClonedSlides() {
		this.additionalClonesCount = this._slidesCount < 3 ? this._slidesCount : 3;
		if (this._slidesCount > 3 || (window.innerWidth < AozSwipeGalleryElement.defaults.DESKTOP_VIEWPORT && this._slidesCount <= 3)) {
			const {
				firstSlideCLone,
				secondSlideClone,
				lastSlideClone,
				penultimateSlideClone,
				thirdSlideClone,
				prePenultimateSlideClone
			} = this._getClonedSlides();

			this._slider.insertBefore(lastSlideClone, this._slides[0]);
			this._slider.appendChild(firstSlideCLone);


			if (this.additionalClonesCount > 1) {
				lastSlideClone.parentNode.insertBefore(penultimateSlideClone, lastSlideClone);
				this._slider.appendChild(secondSlideClone);
				if (this.additionalClonesCount > 2) {
					penultimateSlideClone.parentNode.insertBefore(prePenultimateSlideClone, penultimateSlideClone);
					this._slider.appendChild(thirdSlideClone);
				}
			}

			this._slider.classList.remove(SwipeGalleryElement.defaults.classSliderAnimate);
			this._transformSlider(this._slideWidth * this.additionalClonesCount);
		}
	}
}

if (window.customElements.get(AozSwipeGalleryElement.defaults.elementName) === undefined) {
	window.customElements.define(AozSwipeGalleryElement.defaults.elementName, AozSwipeGalleryElement);
}
