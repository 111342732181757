import {detailsCampaignsTemplate} from './details-campaigns-template';

const isRequired = () => { throw new Error('template is required'); };

export class DynamicSwipeGalleryLookup {
	static getTemplate(type) {
		switch (type) {
			case 'detailsCampaignsTemplate':
				return detailsCampaignsTemplate;
			default:
				isRequired();

		}
	}
}


