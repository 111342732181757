export const detailsCampaignsTemplate = `

	<div class="sc-details-campaigns-swipe-container sc-j-swipe-gallery-container">
		<h3 class="sc-details-campaigns-headline audi-headline-order-3">{{=window.i18n['sc.campaigns.headline']||'sc.campaigns.headline'}}</h3>
		<div class="sc-details-campaigns-swipe-slider-wrapper sc-j-swipe-gallery-slider-wrapper">
			<div class="sc-j-swipe-gallery-slider">
				{{var flexBasisValue = 100 / Math.max(it.numberOfItems, 3);}}
				{{var maxWidth = it.numberOfItems > 1 ? flexBasisValue : 100;}}
				{{~it.preparedContent :campaign :index}}
					<div class="sc-j-swipe-gallery-slide">
						{{~campaign :item}}
							<div class="sc-details-campaigns-swipe-slide-item sc-j-swipe-gallery-slide-item" style="flex-basis: {{=maxWidth}}%; max-width: {{=maxWidth}}%;">
								<div class="sc-details-campaigns-swipe-slide-item-image-sizer sc-j-swipe-gallery-slide-item-image-sizer">
									<div class="sc-details-campaigns-swipe-slide-item-image-wrapper sc-j-swipe-gallery-slide-item-image-wrapper">
										{{? item.campaignImage && item.campaignImage.length > 1}}
											<picture class="sc-j-swipe-gallery-slide-item-picture sc-j-lazy-load-image">
												<img draggable="false" class="sc-details-campaigns-swipe-slide-item-image sc-j-swipe-gallery-slide-item-image" data-image-src="{{=item.campaignImage}}"/>
											</picture>
										{{??}}
											<svg viewBox="0 0 1440 864" class="sc-j-swipe-gallery-slide-item-image">
												<use xlink:href={{=SETUPS.get('stockcar.url.assets')+"/fallback/aud-vtp-fallback-16-9-logo.svg#aud-vtp-fallback-16-9-logo"}}></use>
											</svg>
										{{?}}
									</div>
								</div>
								<div class="sc-details-campaigns-swipe-campaign-text-wrapper sc-hyphenate">
									<h4 class="sc-details-campaigns-swipe-campaign-headline audi-copy-m">{{=item.headline}}</h4>
									<p class="sc-details-campaigns-swipe-campaign-text audi-copy-m">{{=item.description}}</p>
									<a class="sc-details-campaigns-swipe-campaign-link audi-link-m audi-link-m--arrow nm-layerLink" href="{{=item.layerUrl}}?campaignId={{=item.campaignId}}" data-campaign-id="{{=item.campaignId}}" data-campaign-headline="{{=item.headline}}">
										<span class="audi-link-m__text">{{=window.i18n['sc.campaigns.readmore']||'sc.campaigns.readmore'}}</span>
										<svg class="audi-link-m__icon">
											<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/forward-small.svg#audi-icon-forward-small"}}></use>
										</svg>
									</a>
								</div>
							</div>
						{{~}}
					</div>
				{{~}}
			</div>
		</div>
			<ul class="sc-details-campaigns-swipe-dots sc-j-swipe-gallery-dots">
		{{? it.preparedContent.length > 1}}
				{{~it.preparedContent :campaign :index}}
					<li class="sc-j-swipe-gallery-dot" data-index="{{=index}}"></li>
				{{~}}
		{{?}}
			</ul>
	</div>

	<div class="sc-details-campaigns-swipe-navigation-next-wrapper">
		<div class="sc-details-campaigns-swipe-navigation-next sc-j-swipe-gallery-navigation-next" tabindex="0" role="button" aria-label="Next slide"
			aria-disabled="false">
			<svg class="sc-details-campaigns-swipe-navigation-next-icon nm-icon-forward-large" data-direction="next">
				<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/forward-large.svg#nm-icon-forward-large"}}></use>
			</svg>
		</div>
	</div>
	<div class="sc-details-campaigns-swipe-navigation-prev-wrapper">
		<div class="sc-details-campaigns-swipe-navigation-prev sc-j-swipe-gallery-navigation-prev sc-hidden" tabindex="0" role="button"
			aria-label="Previous slide" aria-disabled="false">
			<svg class="sc-details-campaigns-swipe-navigation-prev-icon nm-icon-back-large" data-direction="prev">
				<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/back-large.svg#nm-icon-back-large"}}></use>
			</svg>
		</div>
	</div>
`;

