import {SELECTORS, STCK_STORE} from '@oneaudi/stck-store';
import {SwipeGalleryElement} from './swipe-gallery-element';

export class TilesSwipeGalleryElement extends SwipeGalleryElement {
	constructor() {
		super();
	}

	/**
	 * connectedCallback
	 * @returns {void} void
	 */
	connectedCallback() {
		super.connectedCallback();
		this.unsubscribeViewResultsType = STCK_STORE.observeStateFromStore(SELECTORS.UI.getResultsViewType, this.update.bind(this));
	}

	/**
	 * disconnectedCallback
	 * @returns {void} void
	 */
	disconnectedCallback() {
		super.disconnectedCallback();
		this.unsubscribeViewResultsType();
	}

	/**
	 * update function gets triggered by viewTypeState
	 * @returns {void} void
	 */
	update() {
		setTimeout(() => super.resizeHandler(), 50);
	}
}

if (window.customElements.get('tiles-swipe-gallery-element') === undefined) {
	window.customElements.define('tiles-swipe-gallery-element', TilesSwipeGalleryElement);
}
