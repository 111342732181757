(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("core-utils"), require("stockcars-utils-bundle"));
	else if(typeof define === 'function' && define.amd)
		define("stockcars-swipe-gallery-bundle", ["core-utils", "stockcars-utils-bundle"], factory);
	else if(typeof exports === 'object')
		exports["stockcars-swipe-gallery-bundle"] = factory(require("core-utils"), require("stockcars-utils-bundle"));
	else
		root["stockcars-swipe-gallery-bundle"] = factory(root["core-utils"], root["stockcars-utils-bundle"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__) {
return 