import {dom} from 'core-utils';
import {lazyLoad} from '../stockcars-utils-bundle';

export class SwipeGalleryElement extends HTMLElement {
	constructor() {
		super();
		this._initializeClassVariables();
	}

	_initializeClassVariables() {
		this._snapPercentage = 15;
		this._touchStartX = null;
		this._touchMoveX = null;
		this._moveX = 0;
		this._index = 0;
		this._isLongTouch = null;
		this._slideWidth = 0;
		this._slides = [];
		this._dots = [];
		this._hasConnected = false;
		this._isScrollSwipeEnabled = false;
		this._controlKeyCodes = [37, 39];
		this.hasSlided = false;
		this._isInfiniteEnabled = false;
	}

	/**
	 * getter for clientWidth
	 * @returns {number} computed css width property
	 */
	get clientWidth() {
		return this._slides[0] ? SwipeGalleryElement.getComputedPropertyValue(this._slides[0], 'width') : 0;
	}

	get _calculatedIndex() {
		return this._isInfiniteEnabled ? this._index + 1 : this._index;
	}

	/**
	 * getter for _sliderTranslate3d
	 * @returns {number} css translate3d x value
	 * @private
	 */
	get _sliderTranslate3d() {
		return Math.abs(parseFloat(SwipeGalleryElement.getTranslate3d(this._slider)[0].replace('px', '')));
	}

	/**
	 * static getter for observedAttributes in customElement context
	 * @static
	 * @returns {array<string>} returns string array of observed data attributes
	 */
	static get observedAttributes() {
		return ['data-index'];
	}

	/**
	 * static getter for defaults - selectors, strings, attributes
	 * @static
	 * @returns {object} defaults
	 */
	static get defaults() {
		return {
			selectorGalleryContainer: '.sc-j-swipe-gallery-slider-wrapper',
			selectorGallerySlider: '.sc-j-swipe-gallery-slider',
			selectorGallerySliderItem: '.sc-j-swipe-gallery-slide',
			selectorGalleryNavigationNext: '.sc-j-swipe-gallery-navigation-next',
			selectorGalleryNavigationPrev: '.sc-j-swipe-gallery-navigation-prev',
			selectorGalleryLazyLoad: '.sc-j-swipe-gallery-lazy',
			selectorGalleryCounterActive: '.sc-j-swipe-gallery-counter-active',
			selectorGalleryCounterTotal: '.sc-j-swipe-gallery-counter-total',
			selectorGalleryDots: '.sc-j-swipe-gallery-dot',
			selectorGallerySlideItemImage: '.sc-j-swipe-gallery-slide-item-image',
			classActiveDot: 'sc-j-swipe-gallery-active-dot',
			classSliderAnimate: 'sc-j-swipe-gallery-animate',
			classHideCursorMove: 'sc-j-swipe-gallery-hide-cursor-move',
			attributeSrc: 'data-src',
			selectorLazyLoadGif: '.sc-j-swipe-gallery-lazy-loader',
			hiddenClass: 'sc-hidden'
		};
	}

	/**
	 * static getter for multiple events swipeEvents
	 * @static
	 * @returns {{DOWN: *[], MOVE: *[], UP: *[], OUT: *[]}} multiple events
	 */
	static get swipeEvents() {
		return {
			DOWN: [{window: 'onmousedown', event: 'mousedown'}, {window: 'ontouchstart', event: 'touchstart'}],
			MOVE: [{window: 'onmousemove', event: 'mousemove'}, {window: 'ontouchmove', event: 'touchmove'}],
			UP: [{window: 'onmouseup', event: 'mouseup'}, {window: 'ontouchend', event: 'touchend'}],
			OUT: [{window: 'onmouseout', event: 'mouseout'}, {window: 'ontouchleave', event: 'touchleave'}]
		};
	}

	/**
	 * static getter for multiple events mouseWheelEvents
	 * @static
	 * @returns {{MOVE: *[]}} multiple events
	 */
	static get mouseWheelEvents() {
		return {
			MOVE: [
				{window: 'onwheel', event: 'wheel'},
				{window: 'onmousewheel', event: 'mousewheel'}
			]
		};
	}

	/**
	 * getTranslate3d
	 * @param {HTMLElement} element element
	 * @static
	 * @returns {string[]} string with x,y,z px values
	 */
	static getTranslate3d(element) {
		const values = element.style.transform.split(/\w+\(|\);?/);
		if (!values[1] || !values[1].length) {
			return ['0px'];
		}
		return values[1].split(/,\s?/g);
	}

	/**
	 * getComputedPropertyValue
	 * @param {HTMLElement} element element
	 * @param {string} property css property
	 * @static
	 * @returns {number} returns parsed float of computed css property
	 */
	static getComputedPropertyValue(element, property) {
		const computedCSSValueString = getComputedStyle(element, null).getPropertyValue(property) || '';
		return parseFloat(computedCSSValueString.replace(/px/, ''));
	}

	/**
	 * getEventXPosition
	 * @param {event} event event
	 * @static
	 * @returns {number} returns events x position for desktop or mobile
	 */
	static getEventXPosition(event) {
		return event.pageX || event.changedTouches[0].clientX || event.originalEvent.changedTouches[0].pageX;
	}

	/**
	 * getEventYPosition
	 * @param {event} event event
	 * @static
	 * @returns {number} returns events y position for desktop or mobile
	 */
	static getEventYPosition(event) {
		return event.pageY || event.changedTouches[0].clientY || event.originalEvent.changedTouches[0].pageY;
	}

	/**
	 * attributeChangedCallback - custom elements attributes changed callback function
	 * @param {string} name attribute name
	 * @param {string} oldValue old attribute value
	 * @param {string} newValue old attribute value
	 * @returns {void} void
	 */
	attributeChangedCallback(name, oldValue, newValue) {
		if (this._hasConnected) {
			if (name === 'data-index') {
				this.setNewIndex(parseInt(newValue, 10));
				this._transformSlider(this._index * this.clientWidth);
			}
		}
	}

	/**
	 * custom elements connected callback function
	 * @override
	 * @returns {void} void
	 */
	connectedCallback() {
		this._hasConnected = true;
		this.initializeProperties();
		this.bindContext();
		lazyLoad.register(this);
		if (this._slides.length <= 1) {
			this.classList.add(SwipeGalleryElement.defaults.classHideCursorMove);
			return;
		}
		this.addEvents();
		this._initGalleryInstance();
		if (('ontouchstart' in document.documentElement)) {
			this._hasTouch = true;
		}
	}

	_initGalleryInstance() {
		this._showPrevNextButtons();
		this._setTotalCount();
		this._setActiveCount();
		this._setActiveDot();
		this._initWithIndex();
		this._handleInfinity();
	}

	_handleInfinity() {
		if (this._isInfiniteEnabled && this._slides.length) {
			this._insertClonedSlides();
		}
		else {
			this._isInfiniteEnabled = false;
			this._slider.classList.add(SwipeGalleryElement.defaults.classSliderAnimate);
		}
	}

	_insertClonedSlides() {
		const {firstSlideCLone, lastSlideClone} = this._getClonedSlides();
		this._slider.insertBefore(lastSlideClone, this._slides[0]);
		this._slider.appendChild(firstSlideCLone);
		this._slider.classList.remove(SwipeGalleryElement.defaults.classSliderAnimate);
		this._transformSlider(this._slideWidth);
	}

	_getClonedSlides() {
		const firstSlideCLone = this._slides[0].cloneNode(true);
		const lastSlideClone = this._slides[this._slides.length - 1].cloneNode(true);
		firstSlideCLone.classList.add('sc-j-swipe-gallery-slide-clone');
		lastSlideClone.classList.add('sc-j-swipe-gallery-slide-clone');
		firstSlideCLone.setAttribute('data-original-index', 0);
		lastSlideClone.setAttribute('data-original-index', this._slides.length - 1);

		this.lastSlideClone = lastSlideClone;
		return {firstSlideCLone, lastSlideClone};
	}

	/**
	 * disconnectedCallback
	 * @returns {void} void
	 */
	disconnectedCallback() {
		this.removeEvents();
	}

	/**
	 * _initializeProperties
	 * @returns {void} void
	 */
	initializeProperties() {
		this._slider = dom.getElement(SwipeGalleryElement.defaults.selectorGallerySlider, this);
		this._slides = dom.getElementsArray(SwipeGalleryElement.defaults.selectorGallerySliderItem, this);
		this._slideWidth = this.clientWidth;
		this._slidesCount = this._slides.length;
		this._nextButton = dom.getElement(SwipeGalleryElement.defaults.selectorGalleryNavigationNext, this);
		this._prevButton = dom.getElement(SwipeGalleryElement.defaults.selectorGalleryNavigationPrev, this);
		this._counterActive = dom.getElement(SwipeGalleryElement.defaults.selectorGalleryCounterActive, this);
		this._counterTotal = dom.getElement(SwipeGalleryElement.defaults.selectorGalleryCounterTotal, this);
		this._dots = dom.getElementsArray(SwipeGalleryElement.defaults.selectorGalleryDots, this);
		this._isScrollSwipeEnabled = this.getAttribute('data-enable-scroll-swipe') === 'true';
		this._dotClickEnabled = this.getAttribute('data-enable-dot-click') === 'true';
		this._keyboardDisabled = this.getAttribute('data-disable-keyboard') === 'true';
		this._isInfiniteEnabled = this.getAttribute('data-enable-infinite') === 'true';
	}

	/**
	 * bindContext - bind this to all event handlers
	 * @returns {void} void
	 */
	bindContext() {
		this._clickCaptureHandler = this._clickCaptureHandler.bind(this);
		this._mouseDownHandler = this._mouseDownHandler.bind(this);
		this._mouseMoveHandler = this._mouseMoveHandler.bind(this);
		this._mouseUpHandler = this._mouseUpHandler.bind(this);
		this._prevButtonClickHandler = this._prevButtonClickHandler.bind(this);
		this._nextButtonClickHandler = this._nextButtonClickHandler.bind(this);

		this.resizeHandler = this.resizeHandler.bind(this);
		this._debounceResizeHandler = dom.debounce(this.resizeHandler, 100).bind(this);

		if (!this._keyboardDisabled) {
			this._handleLeftAndRightKeys = this._handleLeftAndRightKeys.bind(this);
		}
		if (this._dotClickEnabled) {
			this._dotClickHandler = this._dotClickHandler.bind(this);
		}
		if (this._isScrollSwipeEnabled) {
			this._scrollHandler = this._scrollHandler.bind(this);
			this._debounceScrollEndHandler = dom.debounce(this._scrollEndHandler, 100).bind(this);
		}
	}

	/**
	 * addEvents
	 * @returns {void} void
	 */
	addEvents() {
		this.classList.remove(SwipeGalleryElement.defaults.classHideCursorMove);
		this._slider.addEventListener('click', this._clickCaptureHandler, true);
		this.addMultipleEvent(this._slider, this._mouseDownHandler, SwipeGalleryElement.swipeEvents.DOWN);
		this._nextButton.addEventListener('click', this._nextButtonClickHandler);
		this._prevButton.addEventListener('click', this._prevButtonClickHandler);
		window.addEventListener('resize', this._debounceResizeHandler);
		if (!this._keyboardDisabled) {
			window.addEventListener('keydown', this._handleLeftAndRightKeys);
		}
		if (this._dotClickEnabled) {
			this._dots.forEach(dot => dot.addEventListener('click', this._dotClickHandler));
		}
		if (this._isScrollSwipeEnabled) {
			this.addMultipleEvent(this._slider, this._scrollHandler, SwipeGalleryElement.mouseWheelEvents.MOVE);
			this.addMultipleEvent(this._slider, this._debounceScrollEndHandler, SwipeGalleryElement.mouseWheelEvents.MOVE);
		}
	}

	/**
	 * removeEvents
	 * @returns {void} void
	 */
	removeEvents() {
		this._slider.removeEventListener('click', this._clickCaptureHandler, true);
		this.removeMultipleEvent(this._slider, this._mouseDownHandler, SwipeGalleryElement.swipeEvents.DOWN);
		this._nextButton.removeEventListener('click', this._nextButtonClickHandler);
		this._prevButton.removeEventListener('click', this._prevButtonClickHandler);
		window.removeEventListener('resize', this._debounceResizeHandler);
		if (!this._keyboardDisabled) {
			window.removeEventListener('keydown', this._handleLeftAndRightKeys);
		}
		if (this._dotClickEnabled) {
			this._dots.forEach(dot => dot.removeEventListener('click', this._dotClickHandler));
		}
		if (this._isScrollSwipeEnabled) {
			this.removeMultipleEvent(this._slider, this._scrollHandler, SwipeGalleryElement.mouseWheelEvents.MOVE);
			this.removeMultipleEvent(this._slider, this._debounceScrollEndHandler, SwipeGalleryElement.mouseWheelEvents.MOVE);
		}
	}

	/**
	 * _initWithIndex
	 * @private
	 * @returns {void} void
	 */
	_initWithIndex() {
		const index = this.getAttribute('data-index') || 0;
		if (index >= 1) {
			this.setNewIndex(parseInt(index, 10));
			setTimeout(() => {
				this._transformSlider(this._index * this.clientWidth);
			}, 10);
		}
	}

	/**
	 * addMultipleEvent
	 * @param {HTMLElement} element element to add event
	 * @param {function} callback callback function
	 * @param {array} events multiple events to add
	 * @param {boolean} isPassive should be passive
	 * @returns {void}
	 */
	addMultipleEvent(element, callback, events) {
		events.forEach((event) => {
			element.addEventListener(event.event, callback);
		});
	}

	/**
	 * removeMultipleEvent
	 * @param {HTMLElement} element element to remove event from
	 * @param {function} callback callback function
	 * @param {array} events multiple events to remove
	 * @returns {void}
	 */
	removeMultipleEvent(element, callback, events) {
		events.forEach((event) => {
			element.removeEventListener(event.event, callback);
		});
	}

	/**
	 * _handleLeftAndRightKeys - key up and down event handler
	 * @param {Event} event event
	 * @private
	 * @returns {void} void
	 */
	_handleLeftAndRightKeys(event) {
		if (!dom.isHidden(this) && this._controlKeyCodes.indexOf(event.keyCode) > -1) {
			event.preventDefault();
			if (event.keyCode === 37) {
				this._prevButtonClickHandler();
			}
			else {
				this._nextButtonClickHandler();
			}
		}
	}

	/**
	 * _scrollEndHandler
	 * @private
	 * @returns {void} void
	 */
	_scrollEndHandler() {
		this.setNewIndex(parseInt(this._getScrollIndex(), 10));
		this._transformSlider(this._calculatedIndex * this.clientWidth);
		this._slider.classList.add(SwipeGalleryElement.defaults.classSliderAnimate);
	}

	/**
	 * _getScrollIndex calculated scroll index when "scroll" finished
	 * @returns {number} nextIndex next index
	 * @private
	 */
	_getScrollIndex() {
		const calculatedIndex = this._moveX / this.clientWidth;
		const fixedIndex = parseFloat(calculatedIndex.toFixed(2));
		const naturalNumber = Number(fixedIndex.toString().split('.')[0]);
		const decimalNumber = Number(fixedIndex.toString().split('.')[1]);

		let nextIndex = this._index;

		if (naturalNumber >= this._index) {
			nextIndex = (decimalNumber > 15 && naturalNumber < this._slides.length - 1) ? naturalNumber + 1 : naturalNumber;
		}
		else {
			nextIndex = (decimalNumber > 85 && naturalNumber > 0) ? naturalNumber - 1 : naturalNumber;
		}
		return nextIndex;
	}

	/**
	 * _scrollHandler - horizontal scroll handler
	 * @param {event} event mousewheel event
	 * @private
	 * @returns {void} void
	 */
	_scrollHandler(event) {
		// TODO: does not work with infinite
		if (Math.abs(event.deltaY) < Math.abs(event.deltaX)) {
			event.preventDefault();
			this._slider.classList.remove(SwipeGalleryElement.defaults.classSliderAnimate);
			this._moveX = event.deltaX < 0 ? this._sliderTranslate3d - Math.abs(event.deltaX) : this._sliderTranslate3d + Math.abs(event.deltaX);
			this._transformSlider(this._moveX);
		}
	}

	/**
	 * _dotClickHandler
	 * @param {event} event click event
	 * @private
	 * @returns {void} void
	 */
	_dotClickHandler(event) {
		event.preventDefault();
		const index = Number(event.target.getAttribute('data-index'));
		if (index > -1) {
			this.setNewIndex(index);
			// TODO: Maybe add this here like in AOZ: this._slider.classList.add(SwipeGalleryElement.defaults.classSliderAnimate);
			this._transformSlider(index * this.clientWidth);
		}
	}

	/**
	 * resizeHandler
	 * @returns {void} void
	 */
	resizeHandler() {
		if (this._slides.length <= 1) {
			return;
		}
		this._slideWidth = this.clientWidth;
		this._transformSlider(this._calculatedIndex * this.clientWidth);
	}

	/**
	 * _detectLongTouch - sets this._isLongTouch true if click/touch hold is at least 250 ms
	 * @private
	 * @returns {void} void
	 */
	_detectLongTouch() {
		this._isLongTouch = false;
		setTimeout(() => {
			this._isLongTouch = true;
		}, 250);
	}

	/**
	 * _preventDefaultOnNonTouchDevices
	 * @param {event} event event
	 * @private
	 * @returns {void} void
	 */
	static _preventDefaultOnNonTouchDevices(event) {
		if (!('ontouchstart' in document.documentElement)) {
			event.preventDefault();
		}
	}

	/**
	 * _mouseDownHandler
	 * @param {Event} event event
	 * @private
	 * @returns {void} void
	 */
	_mouseDownHandler(event) {
		SwipeGalleryElement._preventDefaultOnNonTouchDevices(event);
		this._swipeDone = true;
		this._slideWidth = this.clientWidth;
		this._detectLongTouch();
		this._touchStartX = SwipeGalleryElement.getEventXPosition(event);
		this._touchStartY = SwipeGalleryElement.getEventYPosition(event);
		this._slider.classList.remove(SwipeGalleryElement.defaults.classSliderAnimate);
		this.addMultipleEvent(this._slider, this._mouseMoveHandler, SwipeGalleryElement.swipeEvents.MOVE);
		this.addMultipleEvent(this._slider, this._mouseUpHandler, SwipeGalleryElement.swipeEvents.UP);
		this.addMultipleEvent(this._slider, this._mouseUpHandler, SwipeGalleryElement.swipeEvents.OUT);
	}

	/**
	 * _getScrollDirection
	 * @returns {string} scroll direction
	 * @private
	 */
	_getScrollDirection() {
		const verticalScroll = Math.abs(this._touchMoveY - this._touchStartY);
		const horizontalScroll = Math.abs(this._touchMoveX - this._touchStartX);
		return horizontalScroll >= verticalScroll ? 'HORIZONTAL' : 'VERTICAL';
	}

	/**
	 * _mouseMoveHandler
	 * @param {event} event event
	 * @private
	 * @returns {void} void
	 */
	_mouseMoveHandler(event) {
		SwipeGalleryElement._preventDefaultOnNonTouchDevices(event);
		this._touchMoveY = SwipeGalleryElement.getEventYPosition(event);
		this._touchMoveX = SwipeGalleryElement.getEventXPosition(event);

		if (!this._scrollDir && this._swipeDone) {
			this._swipeDone = false;
			this._scrollDir = this._getScrollDirection();
		}

		if (this._scrollDir === 'HORIZONTAL') {
			if (this._hasTouch && event.cancelable) {
				event.preventDefault();
			}
			this._moveX = this._calculatedIndex * this._slideWidth + (this._touchStartX - this._touchMoveX);
			this._transformSlider(this._moveX);
		}
	}

	/**
	 * _mouseUpHandler
	 * @param {event} event event
	 * @private
	 * @returns {void} void
	 */
	_mouseUpHandler(event) {
		this.removeMultipleEvent(this._slider, this._mouseMoveHandler, SwipeGalleryElement.swipeEvents.MOVE);
		this.removeMultipleEvent(this._slider, this._mouseUpHandler, SwipeGalleryElement.swipeEvents.UP);
		this.removeMultipleEvent(this._slider, this._mouseUpHandler, SwipeGalleryElement.swipeEvents.OUT);

		this._touchEndX = SwipeGalleryElement.getEventXPosition(event);
		this._absoluteSwipeDistance = Math.abs(this._touchStartX - this._touchEndX);
		if (this._isCorrectSwipeDistance(this._absoluteSwipeDistance)) {
			if (this._hasSwipePercentage(this._absoluteSwipeDistance) || !this._isLongTouch) {
				this.setNewIndex();
			}
			this.hasSlided = true;
			this._transformSlider(this._calculatedIndex * this.clientWidth);
		}
		this._slider.classList.add(SwipeGalleryElement.defaults.classSliderAnimate);
		this._scrollDir = null;
		this._swipeDone = true;
	}

	/**
	 * _clickCaptureHandler
	 * @param {event} event event
	 * @returns {void} void
	 * @private
	 */
	_clickCaptureHandler(event) {
		if (this.hasSlided) {
			event.preventDefault();
			event.stopPropagation();
			this.hasSlided = false;
		}
	}

	/**
	 * _transformSlider
	 * @param {number} targetPosition movePixel
	 * @returns {void} void
	 */
	async _transformSlider(targetPosition) {
		if (this._isAnimationActive) {
			return;
		}

		let newTargetPosition = targetPosition;
		if (!this._isInfiniteEnabled) {
			const maxTargetPosition = (this._slidesCount * this._slideWidth);
			if (targetPosition > maxTargetPosition) {
				newTargetPosition = maxTargetPosition;
			}
		}

		if (!this._isInfiniteEnabled) {
			this._slider.style.transform = `translate3d(-${newTargetPosition}px,0,0)`;
			this.hasSlided = false;
		}
		else {
			await this.transitionToPromise(this._slider, 'transform', `translate3d(-${newTargetPosition}px,0,0)`);
			this._handleAnimationDone();
			this.hasSlided = false;
		}
	}

	/**
	 * transitionToPromise
	 * @param {HTMLElement} element element on which is transition
	 * @param {string} property style property
	 * @param {string} value new property style value
	 * @returns {Promise<any>} returns Promise when animation is done
	 */
	transitionToPromise(element, property, value) {
		return new Promise((resolve) => {
			const transformLookup = ['transform', '-webkit-transform'];
			element.style[property] = value;
			const transitionEnded = e => {
				if ((e.propertyName !== property) && !(property === 'transform' && transformLookup.indexOf(e.propertyName) > -1)) {
					return;
				}
				element.removeEventListener('transitionend', transitionEnded);
				resolve();
			};
			element.addEventListener('transitionend', transitionEnded);
		});
	}

	/**
	 * _handleAnimationDone
	 * @private
	 * @returns {void} void
	 */
	_handleAnimationDone() {
		if (this._index === -1) {
			this._slider.classList.remove(SwipeGalleryElement.defaults.classSliderAnimate);
			this.setNewIndex(this._slidesCount - 1);
			this._slider.style.transform = `translate3d(-${this._slideWidth * (this._slidesCount)}px,0,0)`;
		}
		else if (this._index === this._slidesCount) {
			this._slider.classList.remove(SwipeGalleryElement.defaults.classSliderAnimate);
			this.setNewIndex(0);
			this._slider.style.transform = `translate3d(-${this._slideWidth}px,0,0)`;
		}
		this._isAnimationActive = false;
	}

	/**
	 * _isCorrectSwipeDistance
	 * @param {number} absoluteSwipeDistance absoluteSwipeDistance
	 * @returns {boolean} returns if swipe distance is correct to prevent single click swipe
	 * @private
	 */
	_isCorrectSwipeDistance(absoluteSwipeDistance) {
		return absoluteSwipeDistance > 2 && absoluteSwipeDistance <= this.clientWidth;
	}

	/**
	 * _hasSwipePercentage
	 * @param {number} absoluteSwipeDistance absoluteSwipeDistance
	 * @returns {boolean} returns if swipe difference has minimum swipe distance
	 * @private
	 */
	_hasSwipePercentage(absoluteSwipeDistance) {
		return absoluteSwipeDistance > ((this.clientWidth / 100) * this._snapPercentage);
	}

	/**
	 * setNewIndex
	 * @param {number} index index
	 * @returns {void} void
	 */
	setNewIndex(index) {
		if (isNaN(index)) {
			if (this._moveX > this._calculatedIndex * this.clientWidth && this._calculatedIndex < (this._isInfiniteEnabled ? this._slidesCount + 1 : this._slidesCount - 1)) {
				this._index++;
			}
			else if (this._moveX < this._calculatedIndex * this.clientWidth && this._calculatedIndex > 0) {
				this._index--;
			}
		}
		else {
			this._index = index;
		}
		this._setActiveCount();
		this._setActiveDot();
		this._lazyLoadImages();
		this._showPrevNextButtons();
	}

	/**
	 * _setTotalCount sets count elements total count value
	 * @private
	 * @returns {void} void
	 */
	_setTotalCount() {
		if (dom.isElement(this._counterTotal)) {
			this._counterTotal.innerHTML = this._slides.length.toString();
		}
	}

	/**
	 * _setActiveCount sets count elements active count value
	 * @private
	 * @returns {void} void
	 */
	_setActiveCount() {
		if (dom.isElement(this._counterActive)) {
			if (this._index > -1 && (this._index + 1) <= this._slidesCount) {
				this._counterActive.innerHTML = (this._index + 1).toString();
			}
		}
	}

	/**
	 * _setActiveDot
	 * @private
	 * @returns {void} void
	 */
	_setActiveDot() {
		this._dots.forEach((dot, index) => {
			dot.classList.remove(SwipeGalleryElement.defaults.classActiveDot);
			if (index === this._index) {
				dot.classList.add(SwipeGalleryElement.defaults.classActiveDot);
			}
		});
	}

	/**
	 * _showPrevNextButtons - if next and prev button exist, shows/hides them
	 * @private
	 * @returns {void} void
	 */
	_showPrevNextButtons() {
		if (dom.isElement(this._nextButton) && dom.isElement(this._prevButton)) {
			this._toggleNextButton();
			this._togglePrevButton();
		}
	}

	/**
	 * _togglePrevButton - shows prev button if this index is zero, else hides them
	 * @private
	 * @returns {void} void
	 */
	_togglePrevButton() {
		if (!this._isInfiniteEnabled && this._index === 0) {
			this._prevButton.classList.add(SwipeGalleryElement.defaults.hiddenClass);
		}
		else {
			this._prevButton.classList.remove(SwipeGalleryElement.defaults.hiddenClass);
		}
	}

	/**
	 * _toggleNextButton - shows next button if this index length-1, else hides them
	 * @private
	 * @returns {void} void
	 */
	_toggleNextButton() {
		if (!this._isInfiniteEnabled && this._index === this._slidesCount - 1) {
			this._nextButton.classList.add(SwipeGalleryElement.defaults.hiddenClass);
		}
		else {
			this._nextButton.classList.remove(SwipeGalleryElement.defaults.hiddenClass);
		}
	}

	/**
	 * _prevButtonClickHandler
	 * @private
	 * @returns {void} void
	 */
	// this._isInfiniteEnabled &&
	_prevButtonClickHandler() {
		if (this._index === 0) {
			return;
		}
		this._slider.classList.add(SwipeGalleryElement.defaults.classSliderAnimate);
		this.setNewIndex(this._index - 1);
		this._transformSlider(this._calculatedIndex * this.clientWidth);
	}

	/**
	 * _nextButtonClickHandler
	 * @private
	 * @returns {void} void
	 */
	// this._isInfiniteEnabled &&
	_nextButtonClickHandler() {
		if (this._index === this._slides.length-1) {
			return;
		}
		this._slider.classList.add(SwipeGalleryElement.defaults.classSliderAnimate);
		this.setNewIndex(this._index + 1);
		this._transformSlider(this._calculatedIndex * this.clientWidth);
	}

	/**
	 * _lazyLoadImages - loops all images and lazyloads images to load
	 * @private
	 * @returns {void} void
	 */
	_lazyLoadImages() {
		const imagesToLoad = [this._index, this._index - 1, this._index + 1];
		this._slides.forEach(async (slide, index) => {
			if (imagesToLoad.indexOf(index) > -1) {
				SwipeGalleryElement._loadImageLazy(slide);
				if (this._isInfiniteEnabled && this._index === -1) {
					await SwipeGalleryElement._loadImageLazy(this._slides[this._slides.length - 1]);
					SwipeGalleryElement._loadImageLazy(this.lastSlideClone);
				}
			}
		});
	}

	/**
	 * _loadImageLazy
	 * @param {HTMLElement} slide slide
	 * @async
	 * @static
	 * @returns {Promise<void>} void
	 * @private
	 */
	static _loadImageLazy(slide) {
		const lazyItems = dom.getElementsArray(SwipeGalleryElement.defaults.selectorGalleryLazyLoad, slide);
		lazyItems.forEach(async lazyItem => {
			const image = dom.getElement(SwipeGalleryElement.defaults.selectorGallerySlideItemImage, lazyItem);
			const dataSrc = image.getAttribute(SwipeGalleryElement.defaults.attributeSrc);
			if (dataSrc) {
				await dom.preloadImages([{src: dataSrc}]);
				image.src = dataSrc;
				image.removeAttribute(SwipeGalleryElement.defaults.attributeSrc);
				const loader = dom.getElement(SwipeGalleryElement.defaults.selectorLazyLoadGif, lazyItem);
				loader.classList.add(SwipeGalleryElement.defaults.hiddenClass);
			}
		});
	}
}

if (window.customElements.get('swipe-gallery-element') === undefined) {
	window.customElements.define('swipe-gallery-element', SwipeGalleryElement);
}
