import {DynamicSwipeGalleryElement} from './dynamic-swipe-gallery-element';

export class CampaignSwipeGalleryElement extends DynamicSwipeGalleryElement {
	constructor() {
		super();
	}

	/**
	 * defaults - getter for defaults
	 * @returns {{selectorModule: string}} defaults object
	 */
	static get defaults() {
		return {
			selectorModule: '.sc-module[data-module="details-campaigns"]'
		};
	}

	/**
	 * prepareDataForTemplate
	 * @param {array} content content list
	 * @param {number} numberOfItems number of items per slide
	 * @returns {Array} slides slides
	 */
	prepareDataForTemplate(content = [], numberOfItems) {
		const filteredContent = this.getFilteredCampaigns(content);
		if (filteredContent.length) {
			return super.prepareDataForTemplate(filteredContent, numberOfItems);
		}
		else {
			this.hideCampaignModule();
		}
	}

	/**
	 * hideCampaignModule
	 * @returns {void} void
	 */
	hideCampaignModule() {
		const campaignModule = this.closest(CampaignSwipeGalleryElement.defaults.selectorModule);
		if (campaignModule) {
			campaignModule.classList.add(DynamicSwipeGalleryElement.defaults.classHidden);
		}
	}

	/**
	 * getFilteredCampaigns
	 * @param {array} content content
	 * @returns {array} array with valid campaigns
	 */
	getFilteredCampaigns(content) {
		const now = Date.now();
		return content.filter(campaign => CampaignSwipeGalleryElement.isCampaignActive(now, campaign));
	}

	/**
	 * isCampaignActive
	 * @param {Number} now date now
	 * @param {Object} campaign campaign object
	 * @returns {boolean} returns if campaign is valid
	 */
	static isCampaignActive(now, campaign) {
		const {startDate: start, endDate: end} = campaign;
		return !!((start && end) && (Number(start) <= now && Number(end) >= now));
	}
}

if (window.customElements.get('campaign-swipe-gallery-element') === undefined) {
	window.customElements.define('campaign-swipe-gallery-element', CampaignSwipeGalleryElement);
}
